<template>
    <div>
        <v-container fluid class="pt-0 mt-0">
            <HeaderAzul titulo="Configurações"/>
        </v-container>
        <v-list>
            <!--<v-list-item to="/profile/password">
                <v-list-item-avatar><v-icon>mdi-camera</v-icon></v-list-item-avatar>
                <v-list-item-title>Alterar imagem</v-list-item-title>
            </v-list-item>
            <v-divider/>
            <v-list-item to="/profile/change_data">
                <v-list-item-avatar><v-icon>mdi-account</v-icon></v-list-item-avatar>
                <v-list-item-title>Alterar meus dados</v-list-item-title>
            </v-list-item>
            <v-divider/>-->
            <v-list-item to="/configuracao/senha/alterar">
                <v-list-item-avatar><v-icon>mdi-lock-reset</v-icon></v-list-item-avatar>
                <v-list-item-title>Alterar minha senha</v-list-item-title>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>

import HeaderAzul from "@/Views/Estrutura/HeaderAzul";
export default {
    name: "Configuracao",
    components: {HeaderAzul},
    data: () => ({
        cursos : []
    })
}
</script>

<style scoped>
h2 {font-size: 42px; font-weight: lighter; line-height: 110%; color: #00436F;}
h2 strong {font-weight: bold;}
</style>